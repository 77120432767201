const EVENT_DATE_URL_CUTOFF = "2/10/2023";
const COMMUNITY_TAGS = [
  "83eadc53-9133-46a5-9be4-dce52fc1e2fb", // community persona
  "dfa18a0d-2f77-4327-b587-9a88f5b8e619", // community led growth
  "62c8d09b-665d-4ecb-bc7b-e6769e14963a", // Best practices
  "e2f60e38-805b-4307-81bb-9192be4c7820", // Best Practices - Metrics & ROI
  "5a888a5e-0f6d-487d-a426-877fdc9ce6de", // Best Practices - Insights
  "4a190571-c5a0-4e09-ad3b-ddfe66379c3a", // Best Practices - Engage
  "3ba54205-09a8-4ce2-8d12-866e371555e1", // Best Practices - Grow
  "5793e7d1-0516-48d9-a00d-cfbc05f1084d", // Best Practices - Launch
  "53a72a1c-4df4-4a20-861e-be6802016af1", // community ops
];
const SAVE_THESE_TAGS = [
  "269d3573-24ab-4b5a-adf1-5cd08cc275f2", // save
  "39d2ca7f-4862-4ab2-b902-0bf10f1d4c34", // company news
];
const BLOG_CUTOFF_DATE = new Date("2022-01-02");
const BLOG_COMMUNITY_CUTOFF_DATE = new Date("2023-08-01");

module.exports.COMMUNITY_TAGS = COMMUNITY_TAGS;
module.exports.BLOG_COMMUNITY_CUTOFF_DATE = BLOG_COMMUNITY_CUTOFF_DATE;

// see: web/helpers-new.js

/**
 * Generate a path from the document with a slug.
 * @param {*} param0
 * @returns {string}
 */
module.exports.pageDocPath = ({
  _type = "",
  slug = {},
  startDate,
  recurring,
  customPath,
  company,
  videoId,
  prospect,
  menu,
  menuParent,
  title,
}) => {
  const uncommonPrefix = "/uncommon";
  const type = videoId ? "video" : _type;
  let date;
  let useDate;

  switch (type) {
    case "post":
      return `/blog/${slug.current}/`;

    case "prospect":
      return `/hello/${slug.current}/`;

    case "asset":
      return `/resources/${slug.current}/`;

    case "playbook":
      return `/playbooks/${slug.current}/`;

    case "documentation":
      return `/docs/${module.exports.getArticlePath({
        parentId: menu,
        slug: slug.current,
        menuParent,
      })}`;

    case "company":
      return `/hello/${slug.current}/`;

    case "story":
      return `/customers/${slug.current}/`;

    case "offering":
      return `/${slug.current}/`;

    case "signal":
      return `/resources/signals/${slug.current}/`;

    case "signalSource":
      return `/prompts/${slug.current}/`;

    case "prompt":
      return `/prompts/${slug.current}/`;

    case "competitor":
      return `/solutions/compete/${slug.current}/`;

    case "integration":
      return `/integrations/${module.exports.textToSlug(
        company.toLowerCase()
      )}/`;

    case "event":
      date = new Date(startDate);
      useDate = !recurring && new Date(EVENT_DATE_URL_CUTOFF) - date > 0;

      return `${uncommonPrefix}/events/${useDate ? `${module.exports.formatDate(date)}--` : ""
        }${slug.current}/`;

    case "page":
      return `/${slug.current === "/" ? "" : `${slug.current}/`}`;

    // @note required for sitemap
    case "category":
      return customPath ? `/${slug.current}/` : `/resources/${slug.current}/`;

    case "video":
      return videoId ? `/videos/${slug}/` : `/videos/${slug.current}/`;

    case "account":
      return `/research/${title.replace(/\s+/g, "-").toLowerCase()}/`;

    case "redirect":
      return slug.current;

    default:
      return slug.current || "";
  }
};

/**
 * Determines if the node should be shown based on the publishedAt date and categories.
 * @param {*} node 
 * @returns boolean
 */
module.exports.shouldShowNode = (node) => {
  if ((new Date(node?.publishedAt || node._createdAt) < new Date(BLOG_COMMUNITY_CUTOFF_DATE))
    && node?.categories.find(
      (c) => COMMUNITY_TAGS.includes(c._id)
    ) && !node?.categories.find(
      (c) => SAVE_THESE_TAGS.includes(c._id)
    )) return false;

  return true;
}

/**
 * Determines if the page should be built based on the publishedAt date and categories.
 * @param {*} node 
 * @returns boolean
 */
module.exports.shouldBuildPage = (node) => {
  if ((new Date(node?.publishedAt) < new Date(BLOG_CUTOFF_DATE))
    && !node?.categories.find(
      (c) => SAVE_THESE_TAGS.includes(c._id)
    )) return false;

  return true;
}

/**
 * Genreates the path for a documentation article.
 * @param {{ parentId: string, slug: string, menuParent: boolean }} param0
 * @returns string
 */
module.exports.getArticlePath = ({ parentId, slug, menuParent }) => {
  const path = [];
  let currentItem = module.exports.DOCS_MENU.find(
    (item) => item.id === parentId
  );

  while (currentItem) {
    path.unshift(currentItem.slug || currentItem.id);
    if (!currentItem.parent) break;
    currentItem = module.exports.DOCS_MENU.find(
      (item) => item.id === currentItem.parent
    );
  }

  return path.length ? `${path.join("/")}/${menuParent ? "" : `${slug}/`}` : "";
};

/**
 * Quick date formatter for urls.
 * @param {Date} date
 * @returns {string}
 */
module.exports.formatDate = (date) =>
  `${date.getMonth().toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}-${date.getFullYear()}`;

/**
 * Clean path for tracking.
 * @param {*} doc
 * @returns {string}
 */
module.exports.trackPath = (doc) => {
  const path = module.exports.pageDocPath(doc);
  return encodeURIComponent(
    path === "/" ? "homepage" : path.replace(/^\/|\/$/g, "")
  );
};

module.exports.makeFilterDate = (d = new Date()) => {
  const month = `${d.getMonth() + 1}`.padStart(2, "0");
  const day = `${d.getDate()}`.padStart(2, "0");

  return `${d.getFullYear()}-${month}-${day}`;
};

/**
 * @param {{_type: string, children?: {text: string}[]}[] | null} blocks
 * @returns {string}
 */
module.exports.toPlainText = (blocks) =>
  blocks
    ? blocks
      .map((block) =>
        block._type !== "block" || !block.children
          ? ""
          : block.children.map((child) => child.text).join("")
      )
      .join("  ")
    : "";

/**
 * @param {string} text
 * @returns {string}
 */
module.exports.textToSlug = (text = "") => {
  return text.toLowerCase().replace(/\s+/g, "-");
};

// see src/lib/docs-menu-flat.js
module.exports.DOCS_MENU = [
  {
    title: "Get Started",
    id: "get-started",
    path: "/docs/get-started/",
    parent: false,
    expanded: true,
  },
  {
    title: "Core Concepts",
    id: "core-concepts",
    parent: "get-started",
  },
  {
    title: "Signals and Integrations",
    id: "signals",
    path: "/docs/signals/",
    parent: false,
  },
  {
    title: "Common Room Native Signals",
    id: "common-room-native-signals",
    parent: "signals",
  },
  {
    title: "Website Tracking",
    id: "visitor-tracking",
    slug: "website-visitor-tracking",
    parent: "signals",
  },
  {
    title: "Business Data",
    id: "business-integrations",
    parent: "signals",
  },
  // {
  //   title: "Okta",
  //   id: "okta",
  //   parent: "business-integrations",
  // },
  // {
  //   title: "Salesforce Managed Package",
  //   id: "salesforce-managed-package",
  //   parent: "business-integrations",
  // },
  {
    title: "Product Usage",
    id: "product-usage-integrations",
    parent: "signals",
  },
  // {
  //   title: "S3",
  //   id: "s3",
  //   parent: "product-usage-integrations",
  // },
  {
    title: "Community and Social",
    id: "community-and-social-integrations",
    parent: "signals",
  },
  // {
  //   title: "Discourse",
  //   id: "discourse",
  //   parent: "community-and-social-integrations",
  // },
  // {
  //   title: "Slack",
  //   id: "slack",
  //   parent: "community-and-social-integrations",
  // },
  // {
  //   title: "YouTube",
  //   id: "youtube",
  //   parent: "community-and-social-integrations",
  // },
  {
    title: "Custom",
    id: "custom-integrations",
    parent: "signals",
  },
  // {
  //   title: "Zapier / API",
  //   id: "zapier-api",
  //   parent: "custom-integrations",
  // },
  {
    title: "Using Common Room",
    id: "using-common-room",
    path: "/docs/using-common-room/",
    parent: false,
  },
  {
    title: "Segments",
    id: "segments",
    parent: "using-common-room",
  },
  // {
  //   title: "Segment Actions",
  //   id: "segment-actions",
  //   parent: "segments",
  // },
  // {
  //   title: "Segment management",
  //   id: "segment-management",
  //   parent: "segments",
  // },
  {
    title: "Workflows",
    id: "workflows",
    parent: "using-common-room",
  },
  {
    title: "Contacts",
    id: "contacts",
    parent: "using-common-room",
  },
  {
    title: "Activity",
    id: "activity",
    slug: "activity-page",
    parent: "using-common-room",
  },
  {
    title: "Organizations",
    id: "orgs",
    slug: "organizations-page",
    parent: "using-common-room",
  },
  {
    title: "RoomieAI™",
    id: "roomie-ai",
    parent: "using-common-room",
  },
  {
    title: "Reporting",
    id: "reporting",
    slug: "reporting-page",
    parent: "using-common-room",
  },
  {
    title: "Set Preferences",
    id: "set-preferences",
    path: "/docs/set-preferences/account/",
    parent: false,
  },
  {
    title: "Find Help",
    id: "help",
    parent: false,
    hideCard: true,
  },
];
