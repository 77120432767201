import React, { useEffect, useRef, useState } from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { ArrowLeft, ArrowRight } from "../icon/arrow-left";
import { graphql, useStaticQuery } from "gatsby";
import { CardContext } from "../card/context";
import { Card } from "../card/card";
import { SLIDE_SPEED } from "./tabbed";
import { typesMap } from "./resources-list";

import * as styles from "../global/global.module.css";

/**
 * Auto-advance single card deck with navigation.
 *
 * @todo separate query from UX component
 *
 * @todo avoid duplicate code in: carousel-slides.js tabbed.js card-carousel.js carousel-cardswipe.js
 */

const query = graphql`
  query CardCarouselQuery {
    signals: allSanitySignal(
      limit: 5
      sort: { fields: [_createdAt], order: DESC }
      filter: { status: { eq: "published" }, strength: { eq: "High" } }
    ) {
      edges {
        node {
          ...cardNodeSignal
        }
      }
    }
    prompts: allSanityPrompt(
      limit: 5
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        status: { eq: "published" }
        strength: { in: ["High", "Medium"] }
      }
    ) {
      edges {
        node {
          ...cardNodePrompt
        }
      }
    }
  }
`;

export const CardCarousel = ({ types }) => {
  const data = useStaticQuery(query) || {};
  const docs = mapEdgesToNodes(data.signals)
    .concat(mapEdgesToNodes(data.prompts))
    .filter((d) => types.includes(d._type));

  const [active, setActive] = useState(0);
  const [isAutoplayed, setIsAutoplayed] = useState(true);
  const autoPlayRef = useRef();

  useEffect(() => {
    clearInterval(autoPlayRef.current);
    if (isAutoplayed) {
      autoPlayRef.current = setInterval(() => {
        setActive((oldActive) =>
          oldActive < docs.length - 1 ? oldActive + 1 : 0
        );
      }, SLIDE_SPEED);
    }

    return () => clearInterval(autoPlayRef.current);
  }, [isAutoplayed]);

  return (
    <>
      <div className="flex items-center flex-wrap mb-1">
        <div className="flex-grow text-sm text-link font-medium opacity-50 ml-5">
          {/* Featured */}
        </div>
        <div className="hidden sm:flex ml-4 pb-1 justify-end">
          <a
            onClick={() => {
              setActive(active < docs.length - 1 ? active + 1 : 0);
              setIsAutoplayed(false);
            }}
            className={cn(
              "hover:bg-dark-10 hover:text-black",
              styles.carouselButton
            )}
            role="button"
            aria-label="previous"
          >
            <ArrowLeft size={16} />
          </a>
          <a
            onClick={() => {
              setActive(active > 0 ? active - 1 : docs.length - 1);
              setIsAutoplayed(false);
            }}
            className={cn(
              "hover:bg-dark-10 hover:text-black ml-2",
              styles.carouselButton
            )}
            role="button"
            aria-label="next"
          >
            <ArrowRight size={16} />
          </a>
          {/* <a
            onClick={() => {
              setIsAutoplayed(!isAutoplayed);
            }}
            className={cn("group relative", styles.carouselButton)}
            role="button"
            aria-label="autoplay"
          >
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-0 right-0 bottom-0 left-0 bg-dark-10 rounded-lg"></div>
            <img
              src={`/static/animation/circular-progress.${
                isAutoplayed ? "gif" : "png"
              }`}
              width={20}
            />
          </a> */}
        </div>
      </div>
      <div style={{ minHeight: "18rem" }}>
        <CardContext.Provider
          value={{
            ...docs[active],
            hideImage: true,
            showSummary: true,
            showByline: false,
            hideLabels: true,
            hideType: true,
            hideDate: true,
            panels: true,
            cta: typesMap.get(docs[active]?._type)?.cta || "Read more",
          }}
        >
          <Card className="w-full bg-white p-2" />
        </CardContext.Provider>
      </div>
    </>
  );
};
