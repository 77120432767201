import React, { useContext } from "react";
import Container from "./container";
import { Logo } from "../icon/logo";
import { LinkedIn } from "../icon/linkedin";
import { Twitter } from "../icon/twitter";
import {
  ATTRIBUTION_PARAM,
  DEMO_PHRASE,
  DEMO_URL,
  LogoLink,
} from "../sections/snowflake/navigation";
import { Slack } from "../icon/slack";
import { trackPath } from "../../../helpers";
import { SiteContext } from "./site-context";
import { MenuLink, MAIN_MENU_FOOTER } from "../sections/snowflake/navigation";
import { YouTube } from "../icon/youtube";
import {
  JOIN_SLACK_PATH,
  LINKEDIN_URL,
  YOUTUBE_URL,
} from "../sections/snowflake/channels-buttons";
import { cn, isThinPage } from "../../lib/helpers";
import {
  APPLE_SHOW_URL,
  SPOTIFY_SHOW_URL,
} from "../sections/snowflake/podcast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faPodcast } from "@fortawesome/free-solid-svg-icons";

const makeMenus = (slug) => {
  return [
    MAIN_MENU_FOOTER[0],
    {
      label: "Solutions",
      links: [
        {
          label: "Sales (AEs)",
          to: "/solutions/sales/",
        },
        {
          label: "Demand Generation",
          to: "/solutions/demand-generation/",
        },
        {
          label: "Sales Development",
          to: "/solutions/sales-development/",
        },
        {
          label: "Revenue Operations",
          to: "/solutions/revops/",
        },
        {
          label: "Account-based",
          to: "/solutions/abm/",
        },
        {
          label: "Developer Relations",
          to: "/solutions/developer-relations/",
        },
        {
          label: "Open Source",
          to: "/solutions/open-source/",
        },
        {
          label: "Community",
          to: "/solutions/community/",
        },
      ],
    },
    {
      label: "Resources",
      links: [
        {
          label: "Resource Hub",
          to: "/resources/",
        },
        {
          label: "Playbooks",
          to: "/resources/playbooks/",
        },
        {
          label: "Signal Guides",
          to: "/resources/signals/",
        },
        {
          label: "Blog",
          to: "/blog/",
        },
        {
          label: "Customers",
          to: "/customers/",
        },
        {
          label: "Community",
          to: "/community/",
        },
        {
          label: "Office Hours",
          to: "/uncommon/events/office-hours/",
        },
        {
          label: "Docs",
          href: "/docs/",
        },
        {
          label: "Developers",
          to: "/developers/",
        },
        {
          label: "API Docs",
          href: "https://api.commonroom.io/docs/community.html",
          attributes: { target: "_blank" },
        },
      ],
    },
    {
      label: "Company",
      links: [
        {
          label: "Careers",
          to: "/careers/",
        },
        {
          label: "Book a Demo",
          to: `${DEMO_URL}?${ATTRIBUTION_PARAM}=footer--${slug}`,
        },
        {
          label: "Contact Us",
          to: `/contact/?${ATTRIBUTION_PARAM}=footer--${slug}`,
        },
        {
          label: "Privacy",
          to: "/privacy-policy/",
        },
        {
          label: "Terms",
          to: "/terms-of-use/",
        },
        // {
        //   label: "Say hello",
        //   to: "mailto:hello@commonroom.io",
        //   attributes: {
        //     target: "_blank",
        //     rel: "noreferrer",
        //   },
        // },
      ],
    },
  ];
};

const socialLinks = [
  {
    label: "Slack",
    href: JOIN_SLACK_PATH,
    icon: <Slack size={20} color="currentColor" />,
    color: "brand-slack2",
  },
  {
    label: "LinkedIn",
    href: LINKEDIN_URL,
    icon: <LinkedIn />,
    color: "brand-linkedin",
  },
  {
    label: "YouTube",
    href: YOUTUBE_URL,
    icon: <YouTube />,
    color: "brand-youtube",
  },
  {
    label: "X",
    href: "https://twitter.com/CommonRoomHQ",
    icon: <Twitter />,
    color: "brand-twitter",
  },
  {
    label: "Spotify",
    href: SPOTIFY_SHOW_URL,
    icon: <FontAwesomeIcon icon={faSpotify} size="xl" />,
    color: "brand-spotify",
  },
  {
    label: "Apple Podcasts",
    href: APPLE_SHOW_URL,
    icon: <FontAwesomeIcon icon={faPodcast} size="xl" />,
    color: "brand-applepodcasts",
  },
];

export const Footer = () => {
  const site = useContext(SiteContext);
  const path = trackPath(site?.doc);
  const slimLayout = isThinPage(site);

  return (
    <footer className="pt-12 pb-20 md:pb-24 bg-gray-1000 text-white relative overflow-hidden">
      <Container
        maxWidth={slimLayout ? "max-w-screen-lg" : null}
        className="relative"
      >
        <div className="grid grid-cols-1 md:grid-cols-1/3-2/3 gap-12 md:gap-y-0">
          <div>
            <LogoLink className="block text-white h-6">
              <Logo className="h-full" />
            </LogoLink>
            <p className="leading-tight text-light-40 font-medium mt-1 ml-8 lg:w-64">
              {site.token("slogan")}
            </p>
          </div>

          {site.hideNav ? null : (
            <div className="grid grid-cols grid-cols-2 sm:grid-cols-4 gap-4 gap-y-12 ml-8 sm:ml-11 md:ml-0">
              {makeMenus(path).map((menu, i) => (
                <div key={i} className="text-sm">
                  <div className="mb-2 text-light-80">{menu.label}</div>
                  <ul key={i}>
                    {menu.links.map((link, n) =>
                      (link.to || link.href) && !link.skipFooter ? (
                        <li key={n} className="mb-2">
                          <MenuLink
                            link={link}
                            linkClass="block text-light-40 hover:text-white"
                          />
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              ))}
            </div>
          )}
          <div
            className={cn(
              "text-sm text-light-40 md:order-2 flex ml-8 sm:ml-11 md:ml-0",
              site.hideNav ? "justify-self-end" : null
            )}
          >
            {socialLinks.map((link, i) => (
              <a
                key={i}
                href={link.href}
                className={cn(
                  "mr-4 flex items-center hover:text-white transform hover:scale-125 transition-all duration-300",
                  link.color ? `hover:text-${link.color}` : null
                )}
                target={link.href !== JOIN_SLACK_PATH ? "_blank" : null}
                rel="noreferrer"
                {...link.attributes}
                aria-label={link.label}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  );
};
