import React, { useContext, useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  cn,
  mapEdgesToNodes,
  truncateText,
} from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { AdminLink } from "../card/card-grid";
import { pageDocPath, shouldShowNode } from "../../../helpers";
import { SearchPopup } from "./snowflake/search";
import { ArrowLeft } from "../icon/arrow-left";
import { format } from "date-fns";
import { Card, iconMap, PODCAST_TAG_ID } from "../card/card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TinyDownArrow } from "../icon/tiny-down-arrow";
import { ClientContext } from "../global/client-context";
import Masonry from "react-masonry-css";
import { CardContext } from "../card/context";

import * as styles from "../global/global.module.css";

const DEFAULT_LIMIT = 999;
const RESOURCES_SLUG = "resources";

// dual filter menu items will fail to be removed by toggle

export const RESOURCES_MENU = [
  {
    divider: true,
    title: "Types",
  },
  {
    title: "Playbooks",
    slug: { current: "playbooks" },
    type: true,
  },
  {
    title: "Blog posts",
    slug: { current: "blog" },
    type: true,
  },
  {
    title: "Customer stories",
    slug: { current: "customers" },
    type: true,
  },
  {
    title: "Videos",
    slug: { current: "videos" },
    type: true,
  },
  // {
  //   title: "Guides",
  //   slug: { current: "guides" },
  //   type: true,
  // },
  {
    title: "Signal guides",
    slug: { current: "resources/signals" },
    type: true,
    // avoid linking to topic + type combos
    noTopics: true,
  },
  {
    title: "AI prompts",
    slug: { current: "prompts" },
    type: true,
    // avoid linking to topic + type combos
    noTopics: true,
  },
];

const query = graphql`
  query ResourcesListQuery {
    nonVideoPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        status: { eq: "published" }
        mainVideo: { url: { eq: null } }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    videoPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        status: { eq: "published" }
        mainVideo: { url: { ne: null } }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
    assets: allSanityAsset(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        # keep these on two lines, linter breaks the query
        slug: { current: { ne: null } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodeAsset
        }
      }
    }
    playbooks: allSanityPlaybook(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        # keep these on two lines, linter breaks the query
        slug: { current: { ne: null } }
        status: { eq: "published" }
      }
    ) {
      edges {
        node {
          ...cardNodePlaybook
        }
      }
    }
    pages: allSanityPage(
      sort: { fields: [_createdAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        status: { eq: "published" }
        contentType: { in: ["video", "guide", "report", "playbook"] }
      }
    ) {
      edges {
        node {
          ...cardNodePage
        }
      }
    }
    stories: allSanityStory(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeStory
        }
      }
    }
    signals: allSanitySignal(
      sort: { fields: [_createdAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeSignal
        }
      }
    }
    prompts: allSanityPrompt(
      sort: { fields: [_createdAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodePrompt
        }
      }
    }
    videoPages: allSanityVideo(
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodeVideo
        }
      }
    }
    videos: allGoogleSpreadsheetWwWebsiteContentVideos(
      sort: { fields: [publishDate], order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          title
          videoId
          playlist
          slug
          community
          devRel
          gtm
          product
          publishDate
          series
          description
        }
      }
    }
    # isFeatured mixes false/null, sort in component
    tags: allSanityCategory(
      sort: { fields: [orderRank], order: ASC }
      filter: {
        slug: { current: { ne: null } }
        status: { in: ["published", "hidden"] }
      }
    ) {
      edges {
        node {
          _id
          _type
          title
          titlePublic
          slug {
            current
          }
          status
          customPath
          isSeries
          isFeatured
        }
      }
    }
  }
`;

export const typesMap = new Map([
  [
    "post",
    {
      label: "blog posts",
      path: `/${RESOURCES_SLUG}/blog/`,
      cta: "Read post",
    },
  ],
  [
    "asset",
    {
      label: "guides",
      path: `/${RESOURCES_SLUG}/guides/`,
      cta: "Read guide",
    },
  ],
  [
    "video",
    {
      path: `/${RESOURCES_SLUG}/videos/`,
      cta: "Watch video",
    },
  ],
  ["playbook", { cta: "See playbook" }],
  ["story", { cta: "Read full story" }],
  ["signal", { cta: "Read signal guide" }],
  ["page", { cta: "Learn more" }],
  ["prompt", { cta: "Get prompt" }],
  ["competitor", { cta: "See alternative" }],
]);

export const ResourcesList = ({ section, hideFilters, table, tag, type }) => {
  const {
    nonVideoPosts,
    videoPosts,
    assets,
    videoPages,
    videos,
    pages,
    playbooks,
    stories,
    tags,
    signals,
    prompts,
  } = useStaticQuery(query);

  const unique = [
    ...new Map(
      []
        .concat(
          mapEdgesToNodes(playbooks).map((p) => {
            return { ...p, publishedAt: p._createdAt };
          })
        )
        .concat(mapEdgesToNodes(stories))
        .concat(mapEdgesToNodes(prompts))
        .concat(
          mapEdgesToNodes(assets).map((a) => {
            return { ...a, publishedAt: a.publishedAt || a._createdAt };
          })
        )
        .concat(
          mapEdgesToNodes(pages).map((a) => {
            return {
              ...a,
              publishedAt: a._createdAt,
              isVideo: a?.contentType === "video",
            };
          })
        )
        .concat(
          mapEdgesToNodes(videoPosts).filter(v => shouldShowNode(v)).map((a) => {
            return { ...a, isVideo: true };
          })
        )
        .concat(
          mapEdgesToNodes(videoPages).map((a) => {
            return { ...a, isVideo: true };
          })
        )
        .concat(mapEdgesToNodes(nonVideoPosts).filter(v => shouldShowNode(v)))
        .concat(mapEdgesToNodes(signals))
        .concat(
          mapEdgesToNodes(videos).map((v) => {
            return {
              ...v,
              imageUrl: `https://img.youtube.com/vi/${v.videoId}/hqdefault.jpg`,
              url: pageDocPath({ ...v, _type: "video" }),
              _type: "video",
              isVideo: true,
              publishedAt: v.publishDate,
              tags: ["community", "devRel", "gtm"].filter((t) => v[t] === "1"),
              summary: v.description
                ? truncateText({
                  str: v.description,
                  titleStr: v.title,
                  max: 200,
                })
                : null,
            };
          })
        )
        .map((p) => [p.id || p.url, p])
    ).values(),
  ].sort((a, b) => {
    return (a.publishedAt || a._createdAt) < (b.publishedAt || b._createdAt)
      ? 1
      : -1;
  });

  const allNodes =
    section?.types && section.types.length > 0
      ? unique.filter((n) => section.types.includes(n._type))
      : unique;
  const [nodes, setNodes] = useState(false);

  const shortList = !!section?.cards && section.cards <= 6;

  return table ? (
    <DataTable nodes={allNodes} />
  ) : (
    <>
      <div className="flex flex-col md:flex-row gap-6">
        {!hideFilters && (
          <div>
            <div className="mb-6">
              <SearchPopup results="resources" />
            </div>
            <Filters
              nodes={allNodes}
              tags={mapEdgesToNodes(tags).sort((a, _b) => !!a.isFeatured)}
              setNodes={setNodes}
              hideTypeFilters={shortList}
              tag={tag}
              type={type}
            />
          </div>
        )}
        <div className="w-full">
          {nodes === false ? (
            <p className="text-center text-secondary font-medium text-lg py-24 bg-dark-2 rounded-2xl">
              Loading...
            </p>
          ) : nodes.length > 0 ? (
            <div className={styles.fadeIn}>
              <Masonry
                breakpointCols={{
                  default: 3,
                  1020: 2,
                  600: 1,
                }}
                className={styles.masonryGrid}
                columnClassName={styles.masonryGridColumn}
              >
                {nodes
                  .slice(0, section?.cards || DEFAULT_LIMIT)
                  .map((doc, i) => (
                    <CardContext.Provider
                      key={i}
                      value={{
                        ...doc,
                        makeThumbnail: true,
                        hideEvents: true,
                        hideDate:
                          doc._type !== "post" ||
                          doc.categories?.find((c) => c._id === PODCAST_TAG_ID),
                        showByline: false,
                        maxColumns: 3,
                        hideMore: true,
                        allowSummary: true,
                        showSummary: true,
                        showLogo: true,
                        isMasonry: true,
                        cta: doc?.categories?.find(
                          (c) => c.slug.current === "podcast"
                        )
                          ? "Listen to podcast"
                          : typesMap.get(doc._type)?.cta || "Read more",
                        quote: doc._type === "story" ? doc.quote : null,
                        hideTitles: doc._type === "story",
                        title: doc._type === "story" ? false : doc.title,
                        isTall: true,
                        panels: true,
                      }}
                    >
                      <Card className="hover:shadow-lg" />
                    </CardContext.Provider>
                  ))}
              </Masonry>
            </div>
          ) : (
            <p className="text-center text-secondary font-medium text-lg py-24 bg-dark-2 rounded-2xl">
              😭 Sorry, no results. Please pick another category or type.
            </p>
          )}
        </div>
      </div>
      {shortList && (
        <div className="flex justify-center mt-12 text-link font-medium">
          {section.types > 1 ? (
            <a href={`/${RESOURCES_SLUG}/`} className="hover:underline">
              See all resources
            </a>
          ) : (
            <a
              href={
                typesMap.get(section.types[0])?.path || `/${section.types[0]}s/`
              }
              className="hover:underline"
            >
              See all{" "}
              {typesMap.get(section.types[0])?.label || `${section.types[0]}s`}
              <ArrowLeft className="ml-1" rotate={180} />
            </a>
          )}
        </div>
      )}
    </>
  );
};

const Filters = ({ nodes, tags, setNodes, hideTypeFilters, tag, type }) => {
  const client = useContext(ClientContext);
  const [expanded, setExpanded] = useState(false);
  const publishedTags = tags.filter((t) => t.status === "published");

  useEffect(() => {
    client.setFilters(
      [].concat(tag ? [tag.slug.current] : []).concat(type ? [type] : [])
    );

    client.setMenuMatch(tags.find((m) => m.slug === tag?.slug.current));
  }, []);

  useEffect(() => {
    setExpanded(
      (client.menuMatch && !client.menuMatch?.isFeatured) ||
      // search all active filters for "view more" item
      tags
        .filter((t) => client.filters.find((f) => f === t.slug.current))
        .find((t) => !t.isFeatured)
    );
    setNodes(
      !client.filters
        ? nodes
        : nodes.filter((n) => {
          return filterNode(n, client.filters, tags);
        })
    );
  }, [client.filters, client.menuMatch]);

  return (
    <ul className="flex flex-wrap mb-12 flex-col text-secondary tracking-tight">
      <div className="flex whitespace-nowrap text-xs text-secondary uppercase tracking-wide mb-1">
        Topics
      </div>
      {[
        {
          title: "All",
          slug: { current: "resources" },
          customPath: true,
          isFeatured: true,
        },
      ]
        .concat(publishedTags.filter((t) => expanded || t.isFeatured))
        .concat(RESOURCES_MENU)
        .map((m, i) =>
          !hideTypeFilters || (hideTypeFilters && !m.type) ? (
            <li
              key={i}
              className={
                !expanded && !m.isFeatured && !m.divider && !m.type
                  ? "hidden"
                  : null
              }
            >
              {m.divider && (
                <a
                  className="block whitespace-nowrap border-l border-dark-5 hover:border-link p-1 pl-2 text-sm text-auxiliary cursor-pointer hover:text-link"
                  onClick={(e) => {
                    expanded ? setExpanded(false) : setExpanded(true);
                    if (expanded) {
                      window && window.scrollTo(0, 0);
                    }
                  }}
                >
                  See{" "}
                  {expanded
                    ? "less"
                    : `${publishedTags.filter((m) => !m.isFeatured).length
                    } more`}
                  <TinyDownArrow
                    className={cn(
                      "inline-block ml-1",
                      expanded ? "transform rotate-180" : null
                    )}
                  />
                </a>
              )}
              <a
                href={
                  !m.divider
                    ? m.customPath
                      ? `/${m.slug.current}/`
                      : m.noTopics
                        ? `/${m.slug.current}/`
                        : `/${RESOURCES_SLUG}/${m.type && m.slug.current !== type
                          ? `${m.slug.current}/`
                          : type && !m.type
                            ? `${type}/`
                            : ""
                        }${m.type && tag
                          ? `${tag.slug.current}/`
                          : !m.type
                            ? m.slug.current !== tag?.slug.current
                              ? `${m.slug.current}/`
                              : ""
                            : ""
                        }`
                    : null
                }
                className={cn(
                  "flex whitespace-nowrap",
                  !m.divider
                    ? "border-l border-dark-5 hover:border-link p-1 pl-2"
                    : "mt-6",
                  // active
                  !m.divider
                    ? // basic single filter match
                    client.menuMatch?.slug.current === m.slug.current ||
                      // multiple filters
                      (m.slug.current !== "" &&
                        client.filters?.includes(m.slug.current)) ||
                      // empty filter with "all" link
                      (m.slug.current === "" &&
                        (client.filters.length === 0 ||
                          (client.filters.length === 1 &&
                            client.filters[0] === "")))
                      ? "text-primary font-semibold hover:line-through"
                      : "hover:text-link"
                    : "text-xs text-secondary uppercase tracking-wide mb-1"
                )}
              // onClick={(e) => {
              //   e.preventDefault();
              //   const query =
              //     m.slug === ""
              //       ? []
              //       : !client.filters
              //       ? m.slug
              //       : client.filters.includes(m.slug)
              //       ? client.filters.filter((f) => f !== m.slug)
              //       : client.filters.concat([m.slug]);
              //   client.setFilters(query);
              //   client.setMenuMatch(
              //     RESOURCES_MENU.find((m) => m.slug === query.join(","))
              //   );
              //   window.history.pushState({}, "", `?filters=${query.join(",")}`);
              //   window.scrollTo(0, 0);
              // }}
              >
                {m.titlePublic || m.title}
                {/* {m.customPath && (
                <FontAwesomeIcon
                  icon={faExternalLink}
                  size="sm"
                  className="ml-1.5 mt-1.5 h-3"
                />
              )} */}
              </a>
            </li>
          ) : null
        )}
    </ul>
  );
};

/**
 * (TYPE OR TYPE) AND (PERSONA OR PERSONA)
 *
 * @param {*} node
 * @param {[string]} filters
 *
 * @returns {[{*}]}
 */
const TYPES = [
  "videos",
  "blog",
  "guides",
  "playbooks",
  "customers",
  "signals",
  "prompts",
];
const filterNode = (node, filters = [], tags) => {
  const hasType = !!filters.find((f) => TYPES.includes(f));
  const hasTag = !!filters.find((f) => !TYPES.includes(f));

  const typeMatch =
    (filters.includes("videos") && node.isVideo) ||
    (filters.includes("blog") && node._type === "post") ||
    (filters.includes("playbooks") && node._type === "playbook") ||
    (filters.includes("customers") && node._type === "story") ||
    (filters.includes("signals") && node._type === "signal") ||
    (filters.includes("prompts") && node._type === "prompt") ||
    (filters.includes("guides") &&
      (node._type === "asset" ||
        (node._type === "page" &&
          (node?.contentType === "guide" || node?.contentType === "report"))));

  const tagMatch = matchCategories(
    node,
    tags,
    filters.filter((f) => !TYPES.includes(f))
  );

  // @todo Match video tag columns to categories (product, devrel, community)
  const seriesBulkMatch =
    node.videoId &&
    filters &&
    node.series &&
    tags.find((t) => t._id === node.series)?.slug.current === filters[0];

  if ((hasType && hasTag) || seriesBulkMatch) {
    return (typeMatch && tagMatch) || seriesBulkMatch;
  } else if (hasType) {
    return typeMatch;
  } else if (hasTag || seriesBulkMatch) {
    return tagMatch || seriesBulkMatch;
  }
  return true;
};

// note: bulkHeader is used in the google doc data source
export const matchCategories = (doc, tags, filters) =>
  filters.find(
    (f) =>
      doc.categories?.find((c) => c.slug.current === f) ||
      doc.useCases?.find((c) => c.slug.current === f) ||
      doc?.[
      RESOURCES_MENU.concat(tags).find((m) => m.slug?.current === f)
        ?.bulkHeader
      ] == 1
  );

export const ResourcesTable = () => <ResourcesList table={true} />;

const HEADERS = ["#", "Published", "Type", "Edit", "Title", "Tags"];

export const DataTable = ({ nodes }) =>
  nodes ? (
    <table className="w-full text-sm">
      <thead>
        <tr className="bg-dark-5 rounded-lg">
          {HEADERS.map((h, i) => (
            <th key={i} className="text-left p-2">
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {nodes.map((node, i) => {
          const type = node._type || "video";
          return (
            <tr key={i} className="border-t border-dark-10">
              <td className="text-auxiliary text-sm p-2">{nodes.length - i}</td>
              <td className="p-2">
                {format(
                  new Date(node.publishedAt || node._createdAt),
                  "MMM d, yy"
                )}
              </td>
              {iconMap.has(type) ? (
                <td className="p-2 whitespace-nowrap">
                  <span
                    className={cn(
                      "p-1 px-1.5 rounded-md mr-1.5",
                      `bg-${iconMap.get(type).color || "dark-20"}`
                    )}
                  >
                    <FontAwesomeIcon
                      icon={iconMap.get(type).icon}
                      className="text-dark-40"
                    />
                  </span>
                </td>
              ) : (
                <td className="p-2 whitespace-nowrap">
                  {capitalizeFirstLetter(type)}{" "}
                </td>
              )}
              <td>
                <AdminLink doc={node} noOverlay={true} />
              </td>
              <td className="p-2">
                <a href={pageDocPath(node)} className="hover:text-link ml-2">
                  {truncateText({ str: node.title, max: 60 })}
                </a>
              </td>
              <td className="text-xs pt-2 pb-1" style={{ maxWidth: "28rem" }}>
                {node.categories &&
                  node.categories.map((category, i) => (
                    <a
                      key={category._id}
                      href={`/${RESOURCES_SLUG}/?filters=${category.slug.current}`}
                      className="border border-dark-10 bg-white p-1 px-2 rounded-md mr-1 mb-1 inline-block hover:text-purple-brand hover:border-purple-darker hover:bg-purple-light"
                    >
                      {category.titlePublic || category.title}
                    </a>
                  ))}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : null;
