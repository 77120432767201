import React from "react";
import { cn, mapEdgesToNodes } from "../../lib/helpers";
import { graphql, useStaticQuery } from "gatsby";
import { pageDocPath } from "../../../helpers";
import { Card, PODCAST_TAG_ID } from "../card/card";
import Masonry from "react-masonry-css";
import { CardContext } from "../card/context";

import * as styles from "../global/global.module.css";
import { typesMap } from "./resources-list";

const DEFAULT_LIMIT = 999;
const SLUG_PREFIX = "/prompts";

// dual filter menu items will fail to be removed by toggle

export const RESOURCES_MENU = [
  {
    divider: true,
  },
  {
    title: "Signal guides",
    slug: { current: "signals" },
    type: true,
    // avoid linking to topic + type combos
    noTopics: true,
  },
  {
    title: "Prompts",
    slug: { current: "customers" },
    type: true,
  },
];

const query = graphql`
  query PromptListQuery {
    prompts: allSanityPrompt(
      sort: { fields: [title], order: ASC }
      # sort: { fields: [_createdAt], order: DESC }
      filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    ) {
      edges {
        node {
          ...cardNodePrompt
        }
      }
    }
    # signals: allSanitySignal(
    #   sort: { fields: [_createdAt], order: DESC }
    #   filter: { slug: { current: { ne: null } }, status: { eq: "published" } }
    # ) {
    #   edges {
    #     node {
    #       ...cardNodeSignal
    #     }
    #   }
    # }
    # todo use sort by orderRank
    tags: allSanitySignalSource(
      sort: { fields: [orderRank], order: ASC }
      filter: {
        slug: { current: { ne: null } }
        status: { eq: "published" }
        isFeatured: { eq: true }
      }
    ) {
      edges {
        node {
          _id
          _type
          title
          slug {
            current
          }
          status
        }
      }
    }
  }
`;

export const PromptList = ({ section, hideFilters, tag, type, cols }) => {
  const { tags, signals, prompts } = useStaticQuery(query);

  const unique = [
    ...new Map(
      []
        .concat(mapEdgesToNodes(prompts))
        // .concat(mapEdgesToNodes(signals))
        .map((p) => [p.id || p.url, p])
    ).values(),
  ].sort((a, b) => {
    return a.title > b.title ? 1 : -1;
  });

  const allNodes =
    section?.types && section.types.length > 0
      ? unique.filter((n) => section.types.includes(n._type))
      : unique;

  const filteredNodes = tag?.slug?.current
    ? allNodes.filter((doc) =>
      doc.signalSource?.find((s) => s.slug.current === tag.slug.current)
    )
    : allNodes;

  return (
    <div className="flex flex-col md:flex-row gap-12">
      {!hideFilters && (
        <div>
          <FilterLinks
            nodes={allNodes}
            tags={mapEdgesToNodes(tags)}
            tag={tag}
            type={type}
          />
        </div>
      )}
      <div className="w-full">
        {filteredNodes.length > 0 ? (
          <div className={styles.fadeIn}>
            <Masonry
              breakpointCols={
                cols === 3
                  ? { default: 3, 1020: 2, 600: 1 }
                  : { default: 2, 600: 1 }
              }
              className={styles.masonryGrid}
              columnClassName={styles.masonryGridColumn}
            >
              {filteredNodes
                .slice(0, section?.cards || DEFAULT_LIMIT)
                .map((doc, i) => (
                  <CardContext.Provider
                    key={i}
                    value={{
                      ...doc,
                      makeThumbnail: true,
                      hideEvents: true,
                      hideDate:
                        doc._type !== "post" ||
                        doc.categories?.find((c) => c._id === PODCAST_TAG_ID),
                      showByline: false,
                      maxColumns: 3,
                      hideMore: true,
                      allowSummary: true,
                      showSummary: true,
                      showLogo: true,
                      isMasonry: true,
                      cta: doc?.categories?.find(
                        (c) => c.slug.current === "podcast"
                      )
                        ? "Listen to podcast"
                        : typesMap.get(doc._type)?.cta || "Read more",
                      quote: doc._type === "story" ? doc.quote : null,
                      hideTitles: doc._type === "story",
                      title: doc._type === "story" ? false : doc.title,
                      isTall: true,
                      panels: true,
                      showIntentTag: true,
                    }}
                  >
                    <Card className="hover:shadow-lg" />
                  </CardContext.Provider>
                ))}
            </Masonry>
          </div>
        ) : (
          <p className="text-center text-secondary font-medium text-lg py-24 bg-dark-2 rounded-2xl">
            😭 Sorry, no results. Please pick another category.
          </p>
        )}
      </div>
    </div>
  );
};

const FilterLinks = ({ tags, tag }) => (
  <ul className="flex flex-wrap mb-12 flex-col text-secondary tracking-tight">
    {[
      {
        title: "All",
        slug: { current: SLUG_PREFIX },
        customPath: true,
        isFeatured: true,
      },
    ]
      .concat(tags)
      // .concat(RESOURCES_MENU)
      .map((m, i) => (
        <li key={i}>
          <a
            href={!m.divider ? pageDocPath(m) : null}
            className={cn(
              "flex whitespace-nowrap",
              !m.divider
                ? "border-l border-dark-5 hover:border-link p-1 pl-2"
                : "mt-6",
              // active
              !m.divider
                ? // @todo compute active state
                // ? "text-primary font-semibold" :
                "hover:text-link"
                : "text-xs text-secondary tracking-wide"
            )}
          >
            {m.titlePublic || m.title}
          </a>
        </li>
      ))}
  </ul>
);
