import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import { Badge, GetStarted, PageHeader, SectionWrapper } from "./sections";
import PortableText from "./portableText";
import { Button } from "./button";
import { CardGrid } from "./card/card-grid";
import { ArrowLeft } from "./icon/arrow-left";
import { SiteContext } from "./global/site-context";
import { Byline } from "./card/byline";
import { CardContext } from "./card/context";
import { TableOfContents } from "./sections/table-of-contents";
import { pageDocPath } from "../../helpers";
import { MAX_SUGGESTED_POSTS } from "../lib/suggestions";
import {
  ATTRIBUTION_PARAM,
  SIGNUP_PHRASE,
  SIGNUP_URL,
} from "./sections/snowflake/navigation";
import { ImageBuilder } from "./global/image-builder";
import { SectionContext } from "./sections/context";
import { BackToZone } from "./back-to-zone";

export const PlaybookPage = ({
  _id,
  title,
  _rawExcerpt,
  integrations,
  suggestions,
  _rawBody,
  authors,
  teams,
  skill,
  suggest,
}) => {
  const site = useContext(SiteContext);
  // @todo standardize with page setting
  const isHelloPage = site.doc.slug.current.match(/hello\//);
  const hasBanner = site.banners.length > 0;

  return (
    <>
      <SectionWrapper
        {...{
          noHeaders: true,
          background: "lavenderUltraLight",
          name: "header",
          backgroundCentered: true,
          padding: hasBanner ? "bottom" : null,
          container: "large",
        }}
      >
        <div>
          {!isHelloPage && <BackToZone to="/resources/playbooks/" text="See all playbooks" />}
          {title && (
            <PageHeader
              section={{
                header: title,
                _rawSummary: _rawExcerpt.concat(getStartedButtonBlock),
              }}
              detailsBox={
                <div className="bg-white p-6 rounded-xl relative">
                  <p className="text-secondary font-medium text-sm mb-2">
                    Teams:
                  </p>
                  <p className="font-semibold">{teams.join(", ")}</p>
                  <p className="text-secondary font-medium text-sm mt-6 mb-2">
                    Sources:
                  </p>
                  <p>
                    <IntegrationsTiles integrations={integrations} />
                  </p>
                  <ImageBuilder
                    src="/static/img/emoji-point.svg"
                    className="absolute -bottom-12 right-16 transform -rotate-45"
                  />
                </div>
              }
            />
          )}
        </div>
      </SectionWrapper>

      {site.hideNav && (
        <SectionWrapper padding="none">
          <Badge />
        </SectionWrapper>
      )}

      {authors.length > 0 && (
        <SectionWrapper padding="top" container="large">
          <div className="pt-3 pb-6 md:pt-0">
            <CardContext.Provider value={{ authors }}>
              <Byline />
            </CardContext.Provider>
          </div>
        </SectionWrapper>
      )}

      <SectionWrapper padding="bottom" container="large">
        <TableOfContentsSection {...{ _rawBody }} />
      </SectionWrapper>

      <SectionWrapper
        anchor="more"
        container={suggestions.length > 0 ? "large" : "thin"}
        header={site.token("suggestions")}
        background="lavenderUltraLight"
      >
        {suggestions.length > 0 ? (
          <CardGrid
            nodes={suggestions}
            panels={true}
            showSummary={true}
            makeThumbnail={true}
            browseMoreHref="/playbooks/"
            browseMoreText="See all playbooks"
            attribution={{ action: "suggestion", label: "playbook" }}
            maxColumns={3}
          />
        ) : (
          <CardGrid
            nodes={suggest.slice(0, MAX_SUGGESTED_POSTS)}
            browseMoreHref="/playbooks/"
            browseMoreText="See all playbooks"
            makeThumbnail={true}
            showSummary={true}
            maxColumns={2}
            allowSummary={true}
            panels={true}
            attribution={{ action: "suggestion" }}
          />
        )}
      </SectionWrapper>

      <SectionWrapper container="large" background="mint">
        <GetStarted />
      </SectionWrapper>
    </>
  );
};

export const TableOfContentsSection = ({ _rawBody }) => (
  <div className="grid md:grid-cols-1/3-2/3 relative pt-8">
    <div className="md:mr-12">
      <div className="sticky top-24 left-0" style={{ maxWidth: 370 }}>
        <TableOfContents blocks={_rawBody} />
      </div>
    </div>
    <div className="">{_rawBody && <PortableText blocks={_rawBody} />}</div>
  </div>
);

const getStartedButtonBlock = [
  {
    _type: "block",
    style: "normal",
    children: [
      {
        _type: "span",
        marks: ["buttonLink"],
        text: SIGNUP_PHRASE,
        _key: "123abc",
      },
    ],
    markDefs: [
      {
        _key: "buttonLink",
        _type: "link",
        href: `${SIGNUP_URL}?${ATTRIBUTION_PARAM}=cta-button--playbook`,
        style: "buttonBlack",
        icon: "none",
      },
    ],
  },
];

export const IntegrationsTiles = ({ integrations, unlinked }) => {
  const site = useContext(SiteContext);
  const section = useContext(SectionContext);
  const linked = !unlinked && site.isActive("linkIntegrations");

  return integrations.map((integration) => (
    <a
      href={linked ? pageDocPath(integration.docRef) : null}
      className={cn(
        "inline-block whitespace-nowrap",
        linked ? "hover:text-link mr-4 mb-2" : "mr-2",
        section.isDarkBg ? "bg-white rounded-md p-1 pb-0.5 flex min-h-7 items-center" : null
      )}
      title={integration.company}
      key={integration._id}
    >
      <ImageBuilder
        image={integration.mainImage}
        width={20}
        className={cn("inline-block relative -top-px", !unlinked ? "mr-2" : null)}
        alt={`${integration.company} logo`}
      />
      {!unlinked && integration.company}
    </a>
  ));
};
