import React, { useContext } from "react";
import {
  capitalizeFirstLetter,
  cn,
  imageBuilder,
  truncateText,
} from "../../lib/helpers";
import { Byline } from "./byline";
import { CardContext } from "./context";
import { PublishDate } from "./publish-date";
import { pageDocPath } from "../../../helpers";
import { format, isFuture } from "date-fns";
import { toPlainText } from "../../lib/helpers";
import { Pills } from "./pills";
import { SiteContext } from "../global/site-context";
import { ArrowRight } from "../icon/arrow-left";
import { ImageBuilder } from "../global/image-builder";
import {
  faDesktop,
  faUsers,
  faCodePullRequest,
  faGlobe,
  faMoon,
  faCode,
  faBook,
  faArrowsSplitUpAndLeft,
  faBookOpenReader,
  faVideo,
  faSignal,
  faPodcast,
  faRobot,
  faArrowCircleUp,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import {
  faThumbsUp,
  faIdCard,
  faComments,
  faNewspaper,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import PortableText from "../portableText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SignalPill } from "../../templates/signal";
import { colorMap } from "../global/global-nav";
import { Logo } from "../icon/logo";
import { IntegrationsTiles } from "../playbook-page";
import { SectionContext } from "../sections/context";

import * as styles from "./card.module.css";

export const iconMap = new Map([
  ["product", { icon: faDesktop }],
  ["crm", { label: "CRM", icon: faIdCard }],
  ["gtm", { label: "CRM", icon: faIdCard }],
  ["social", { icon: faThumbsUp }],
  ["community", { icon: faUsers }],
  ["code", { label: "Open-source", icon: faCodePullRequest }],
  ["openSource", { label: "Open-source", icon: faCodePullRequest }],
  ["website", { icon: faGlobe }],
  ["forum", { icon: faComments }],
  ["news", { label: "News + events", icon: faNewspaper }],
  ["dark", { label: "Dark funnel", icon: faMoon }],
  ["custom", { icon: faCode }],
  ["email", { icon: faEnvelope }],
  ["native", { crIcon: true }],
  ["post", { label: "Blog post", icon: faNewspaper, color: "peach" }],
  ["playbook", { icon: faArrowsSplitUpAndLeft, color: "mint", size: "sm" }],
  ["page", { label: "Guide", icon: faBook, color: "melon" }],
  ["asset", { label: "Guide", icon: faBook, color: "melon" }],
  [
    "story",
    {
      label: "Customer Story",
      icon: faBookOpenReader,
      color: "robin",
    },
  ],
  ["video", { icon: faVideo, color: "dark-10" }],
  [
    "signal",
    {
      label: "Signal Guide",
      icon: faSignal,
      size: "sm",
      color: "purple-default",
    },
  ],
  ["prompt", { icon: faRobot, size: "sm", color: "lime" }],
  ["account", { icon: faPeopleGroup, color: "mint" }],
  ["podcast", { icon: faPodcast, color: "lemon" }],
  ["starter", { icon: null }],
  ["team", { icon: null }],
  ["starter add-on", { icon: null }],
  ["team add-on", { icon: null }],
  ["enterprise add-on", { icon: null }],
  ["add-on", { icon: null }],
]);

export const PODCAST_TAG_ID = "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b";
const COMMON_ROOM_SIGNAL_PHRASE = "CR native";

export const MetaDetails = ({ card, noImage, isPodcast, isDarkBg }) => {
  const displayDate = card.showByline ? (
    <Byline />
  ) : (
    <div className={cn(
      "text-sm flex",
      isDarkBg ? "text-light-60" : "text-dark-50"
    )}
    >
      <PublishDate />
      {card.readingTimeInMinutes && (
        <>
          <span className="mx-2">&middot;</span>
          {card.readingTimeInMinutes}min read
        </>
      )}
    </div>
  );

  const summary = card.summary || toPlainText(card._rawExcerpt);
  const showSummary =
    summary &&
    (!card.quote || card.hideType && !card.showLogo) &&
    (card.showSummary || (card.allowSummary && noImage));

  return (
    <>
      {!card.hideDate && card._type === "post" && (
        <div className="mb-4">{displayDate}</div>
      )}
      {showSummary && (
        <div className={cn(
          "overflow-hidden text-sm leading-relaxed",
          isDarkBg ? "text-light-80" : null
        )}
        >
          <div className={styles.excerpt}>
            {card.fullSummary ? (
              <PortableText blocks={card._rawExcerpt} />
            ) : (
              truncateText({ str: summary, titleStr: card.title, max: 260 })
            )}
          </div>
        </div>
      )}
      <div className="mt-2">
        {card.partner && <TinyPills list={["Available via partner"]} />}
      </div>

      {!card.makeThumbnail &&
        !card.hideLabels &&
        (card.categories || card.isVideo) && (
          <div
            className={cn(
              "flex items-center flex-wrap",
              showSummary ? "mt-4" : null
            )}
          >
            <Pills
              tags={card.categories}
              bulkTags={card.tags}
              showSubTags={card.showSubTags}
            />
          </div>
        )}
    </>
  );
};

export const IMAGE_ASPECT_RATIO = 1.9;

export const EMOJI_MAP = new Map([
  ["dark-funnel", "keyhole-emoji.svg"],
  ["community-teams", "emoji-peeps.svg"],
  ["commercial-open-source-software", "emoji-avocado-vertical.svg"],
  ["product-led-growth", "emoji-glasses-face.svg"],
  ["sales", "emoji-arrow-up-right.svg"],
]);

const BACKGROUNDS_WITH_FILLED_CARDS = [
  "lightLavender",
  "gradientLavender",
  "lightGreen",
  "lavenderUltraLight",
];

export const Card = ({ className, unlinkedOverlay }) => {
  const card = useContext(CardContext);
  const section = useContext(SectionContext);
  const site = useContext(SiteContext);

  const isPodcast = card.categories?.find((c) => c._id === PODCAST_TAG_ID);

  const pastYTcutoff = card?.publishDate
    ? new Date(card.publishDate) > new Date("2023-02-06")
    : null;

  const noImage = card.hideMainMedia || card.hideImage || card.minimal;
  const showImage =
    !noImage &&
    (card?.mainImage?.asset || card?.cardImage?.asset || card?.imageUrl) &&
    ((card.makeThumbnail && (isPodcast || (card.imageUrl && pastYTcutoff))) ||
      !card.makeThumbnail);

  const image = card.graphic || card.cardImage || card.mainImage;
  const imageWidth = card.jumboLayout
    ? 1344
    : card.maxColumns === 1
      ? 1152
      : 836;
  const imageHeight = Math.round(imageWidth / IMAGE_ASPECT_RATIO);
  const imageSrc = showImage
    ? card?.imageUrl
      ? card?.imageUrl
      : imageBuilder(image)
        .width(imageWidth)
        .height(imageHeight)
        .quality(99)
        .auto("format")
        .url()
    : false;
  const horizontal = card.horizontalImage && site.isNotMobile;

  const logo = card.showLogo
    ? card?.companies?.[0]?.mainImage || card?.company?.mainImage
    : null;

  const skipLink =
    card.skipLink || (card._type === "signal" && card.status !== "published");

  const paddedCards = card._type === "story" && site.isHomepage;

  return (
    <a
      href={skipLink ? null : card.url || pageDocPath(card)}
      className={cn(
        "relative inline-block group rounded-xl transition-card duration-200 w-full",
        card._type === "quotable" || card.skipLink
          ? null
          : "hover:shadow-lg",
        card.isMasonry ? "mb-4 min-h-56" : "h-full",
        showImage ? (horizontal ? "grid grid-cols-1/3-2/3" : "flex-col") : "",
        card.jumboLayout ? "grid-flow-row" : "",
        card.minimal ? "pb-1" : "",
        // card.isTall ? "h-72 md:h-96" : "",
        BACKGROUNDS_WITH_FILLED_CARDS.includes(section?.background) || BACKGROUNDS_WITH_FILLED_CARDS.includes(site?.doc.backgroundColor) && section?.background !== "black"
          ? "bg-white"
          : section.isDarkBg
            ? "bg-light-5"
            : "bg-dark-2",
        className
      )}
      target={card.targetBlank ? "_blank" : null}
      onClick={
        card.attribution
          ? () => {
            site.metrics.logClick(card.attribution.action);
          }
          : null
      }
    >
      {/* general card overlay */}
      {(!skipLink || !!unlinkedOverlay) && (
        <div
          className={cn(
            "absolute top-0 right-0 bottom-0 left-0 z-10 opacity-0 transition-opacity rounded-xl overflow-hidden",
            skipLink && !!unlinkedOverlay
              ? "group-hover:opacity-100 bg-white"
              : section.isDarkBg ? "group-hover:opacity-10 bg-black shadow-lg" : "group-hover:opacity-2 bg-black"
          )}
        >
          {unlinkedOverlay}
        </div>
      )}
      {/* {card.hideTitles && showImage && (
        <div className="absolute p-4">{card.title}</div>
      )} */}

      {card.makeThumbnail && !card.hideType ? (
        <div className={cn("p-4", !showImage ? "pb-0" : null)}>
          <TinyPills list={isPodcast ? ["podcast"] : [card._type]} isDarkBg={section.isDarkBg} />
        </div>
      ) : null}

      {showImage ? (
        <>
          {horizontal ? (
            <div
              className="order-2 bg-no-repeat bg-cover rounded-r-xl"
              style={{
                backgroundImage: `url('${imageSrc}')`,
                backgroundPosition: "center",
              }}
            ></div>
          ) : (
            <ImageBuilder
              src={imageSrc}
              height={imageHeight}
              width={imageWidth}
              alt={
                card?.cardImage || card?.imageUrl
                  ? card.title
                  : card.mainImage.alt
              }
              className={cn(
                "relative w-full",
                horizontal
                  ? "rounded-r-xl"
                  : card.makeThumbnail
                    ? "rounded-none"
                    : "rounded-t-xl"
              )}
              style={{
                aspectRatio: "1.9",
                maxWidth: card.maxColumns !== 1 ? "36rem" : null,
              }}
            />
          )}
        </>
      ) : null}
      <div
        className={cn(
          "text-inherit relative mx-auto overflow-hidden p-4",
          paddedCards ? "p-4 md:p-7" : null,
          card.cta ? "h-full pb-10 md:pb-16" : null,
          card._type === "signal"
            ? "md:p-5 md:pr-4 h-full pb-10 md:pb-16"
            : null
        )}
        style={{
          minHeight: "auto",
        }}
      >
        <InviteDetails card={card} />
        {card.title && (!card.hideTitles || !showImage) && !(isPodcast && !card.graphic) && (
          <div className="break-words inline-block w-full mb-2">
            {card.gated && (
              <ImageBuilder
                src="/static/img/icons/lock.svg"
                className="float-right ml-6 w-4"
              />
            )}
            <h3
              className={cn(
                card.minimal
                  ? "text-lg font-semibold"
                  : card.jumboLayout
                    ? "text-xl md:text-2xl"
                    : noImage && !card.makeThumbnail
                      ? "text-2xl font-medium md:leading-tight"
                      : "text-lg md:text-xl font-semibold", // most common
                "tracking-tight md:leading-snug"
              )}
            >
              {card.title}
            </h3>
          </div>
        )}
        {logo && !card.makeThumbnail && (
          <ImageBuilder
            image={logo}
            quality={100}
            height={80}
            style={{
              maxHeight: "100px",
              maxWidth: "180px",
            }}
            className="-mt-4"
            alt={card.companies?.[0]?.title || card.company?.title}
          />
        )}
        <StoryDetails card={card} />
        <QuoteDetails card={card} logo={logo} isPodcast={isPodcast} />
        <MetaDetails card={card} noImage={noImage} isPodcast={isPodcast} isDarkBg={section.isDarkBg} />
        {card._type === "invite" && <Byline />}
        <IntegrationDetails card={card} />
        <SignalDetails card={card} />
        <CardCta
          isDarkBg={section.isDarkBg}
          className={paddedCards ? "mt-2 md:mt-0 md:ml-3 mb-1" : null}
          card={card}
        />
      </div>
    </a>
  );
};

export const CardCta = ({ card, className, isDarkBg }) => card?.cta ? (
  <div
    className={cn(
      "mt-4 flex items-center text-sm font-medium absolute bottom-6 left-4",
      card?.skipLink
        ? "text-secondary"
        : isDarkBg,
      card?.hideType ? "text-link" : "text-auxiliary",
      className
    )}
  >
    {card?.cta}
    {!card?.skipLink && (
      <div className="ml-1.5 relative top-px">
        <ArrowRight />
      </div>
    )}
  </div>
) : null;

const InviteDetails = ({ card }) =>
  card._type === "invite" ? (
    <div
      className={cn(
        "mb-6 p-2 p-3 rounded-md flex justify-between items-center",
        isFuture(new Date(card.startDate))
          ? card.url
            ? "bg-purple-darker"
            : "bg-mint"
          : "bg-dark-10"
      )}
    >
      <div className="font-semibold mr-2">
        {card.startDate && format(new Date(card.startDate), "MMMM d")}
      </div>
      <div>{card.location}</div>
    </div>
  ) : null;

const IntegrationDetails = ({ card }) =>
  card.integrations ? (
    <div className="flex items-top mt-4">
      <IntegrationsTiles integrations={card.integrations} unlinked={true} />
    </div>
  ) : null;

const QuoteDetails = ({ card, logo, isPodcast }) =>
  card.isTall ? (
    card.quote ? (
      <div className={card.makeThumbnail ? "mb-4" : "mb-4"}>
        <CardContext.Provider
          value={{
            authors: [
              {
                author: card.quote.authors
                  ? card.quote.authors[0].author
                  : card.quote.author,
              },
            ],
            skipAuthorLink: true,
          }}
        >
          <PortableText blocks={card.quote._rawExcerpt} className="mb-6" />
          {logo && card.makeThumbnail && (
            <ImageBuilder
              image={logo}
              quality={100}
              height={80}
              style={{
                maxHeight: "100px",
                maxWidth: "180px",
              }}
              className="-mt-4"
            />
          )}
          <Byline hideBorder={true} skipCompany={card.showLogo} />
        </CardContext.Provider>
      </div>
    ) : null
  ) : null;

// @todo group by source group not sourceType

const SignalDetails = ({ card }) =>
  card.sourceType && card.strength ? (
    <>
      <div className="flex flex-wrap my-2">
        <TinyPills
          list={
            card._type === "prompt"
              ? card.sourceType.filter((s) => s !== "custom")
              : card.sourceType
          }
        />
      </div>
      {card.showIntentTag && (
        <div className="absolute bottom-4 right-4 md:bottom-5 md:right-5 whitespace-nowrap">
          <SignalPill strength={card.strength} />
        </div>
      )}
    </>
  ) : null;

export const TinyPills = ({ list, isDarkBg }) => (
  <p className="leading-tight text-xs flex flex-wrap">
    {list.map((p, key) => {
      const icon = iconMap.get(p);

      return (
        <span
          key={key}
          className={cn(
            "flex items-center px-2 py-1 mr-1 mb-1 whitespace-nowrap rounded-md",
            icon?.color ? `bg-${icon.color}` : "text-purple-deep bg-dark-5",
            isDarkBg ? "text-black" : null,
          )}
          style={
            icon?.color && !isDarkBg
              ? {
                color: colorMap.get(icon.color),
              }
              : null
          }
        >
          {icon?.crIcon ? (
            <>
              <Logo iconOnly={true} className="w-4 mr-1" />
              {COMMON_ROOM_SIGNAL_PHRASE}
            </>
          ) : iconMap.has(p) ? (
            <>
              <FontAwesomeIcon
                icon={iconMap.get(p).icon}
                size={iconMap.get(p).size || "lg"}
                className="mr-1.5"
              />
              {iconMap.get(p).label || capitalizeFirstLetter(p)}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faBook} size="lg" className="mr-2 max-h-4" />
              {capitalizeFirstLetter(p)}
            </>
          )}
        </span>
      );
    })}
  </p>
);

const StoryDetails = ({ card }) =>
  card._type === "story" && card?.statistics?.[0] ? (
    <div
      className={cn(
        "rounded-full text-black font-medium px-3 py-2 mb-4 inline-block bg-green-emerald text-white text-sm"
      )}
    >
      <p className="flex gap-2 items-center">
        <FontAwesomeIcon icon={faArrowCircleUp} size="sm" className="max-h-4" />
        {card.statistics[0].number} {card.statistics[0].suffix}
      </p>
    </div>
  ) : null;
