import React, { useContext } from "react";
import { SiteContext } from "../global/site-context";
import { pageDocPath, trackPath } from "../../../helpers";
import { Button } from "../button";
import {
  ATTRIBUTION_PARAM,
  DEMO_PHRASE,
  DEMO_URL,
  SIGNUP_PHRASE,
  SIGNUP_URL,
} from "./snowflake/navigation";
import { ArrowLeft } from "../icon/arrow-left";
import { Divider } from ".";

export const TableOfContents = ({ blocks, className }) => {
  const anchors = blocks?.filter((b) => b._type === "anchor");
  const site = useContext(SiteContext);
  const path = trackPath(site.doc);

  return anchors ? (
    <div className="mb-10 text-sm">
      <a className="mb-2 text-secondary block" href="#top">
        On this page
      </a>
      <ul className="font-medium">
        {anchors.map((h, i) => (
          <li key={h._key}>
            <a
              href={
                i === 0
                  ? "#top"
                  : `${pageDocPath(site?.doc)}#anchors--${h._key}`
              }
              className="ml-1 pl-3 py-2 border-l border-dark-10 hover:border-link hover:text-link block"
            >
              {h.label}
            </a>
          </li>
        ))}
      </ul>
      <div className="hidden md:block">
        <p className="text-auxiliary mt-12 mb-4 max-w-2/3">{site.token("slogan")}</p>
        <Button
          text={SIGNUP_PHRASE}
          href={`${SIGNUP_URL}?${ATTRIBUTION_PARAM}=toc--${path}`}
          bgColor="bg-dark-10"
          className="mb-5"
          icon={<ArrowLeft rotate={180} />}
          iconRight={true}
          isSmall={true}
        />
        <p className="font-medium">
          <a
            href={`${DEMO_URL}?${ATTRIBUTION_PARAM}=toc--${path}`}
            className="text-secondary hover:text-link"
          >
            {DEMO_PHRASE}
          </a>
        </p>
      </div>
    </div>
  ) : null;
};

// const filter = (ast, match) =>
//   ast.reduce((acc, node) => {
//     if (match(node)) acc.push(node);
//     if (node.children) acc.push(...filter(node.children, match));
//     return acc;
//   }, []);
