// @ts-nocheck
import React, { useContext } from "react";
import PortableText from "../../portableText";
import { VideoPopover } from "../../video-popover";
import { cn } from "../../../lib/helpers";
import { Image } from "../featured-media";
import { SectionWrapper } from "../wrapper";
import { ImageBuilder } from "../../global/image-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { DEMO_PHRASE, DEMO_URL, SIGNUP_PHRASE, SIGNUP_URL } from "./navigation";
import { Button } from "../../button";
import { Pill } from "../../card/pills";

import * as marqueeStyles from "./marquee.module.css";
import { EyebrowPill } from "../../serializers";
import { SiteContext } from "../../global/site-context";

export const PRODUCT_VIDEO_ID = "1059867530";
const MOBILE_IMAGE = "/static/img/hero_video_mobile.png";
const HERO_LABEL = "Customer Intelligence Platform";

export const GRADIENT_BACKGROUND_STYLE =
  "radial-gradient(56.12% 73.00% at 37.75% -3.18%, rgba(107, 255, 228, 0.25) 0%, rgba(107, 255, 228, 0.00) 100%), radial-gradient(58.30% 72.52% at 69.25% 58.49%, rgba(207, 148, 59, 0.45) 0%, rgba(255, 143, 107, 0.00) 100%), linear-gradient(58deg, rgba(211, 183, 255, 0.15) 0%, rgba(235, 233, 117, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.70) 100%), #D3B7FF";

// section.mainImage is used for video preview

export const Hero = ({ section }) => (
  <div
    style={{ background: GRADIENT_BACKGROUND_STYLE }}
    className="relative overflow-hidden pt-12 md:pt-8"
  >
    <div className="absolute opacity-80 top-24">
      <Image
        src="/static/img/dot-circle.svg"
        alt={section.header}
        width={800}
      />
    </div>
    <SectionWrapper container="large">
      {/* <div className="max-w-screen-lg mx-auto py-40 relative z-10"> */}
      <div className="lg:flex pb-12 lg:py-12">
        <div
          style={{
            maxWidth: "545px",
          }}
        >
          <PortableText
            blocks={section._rawSummary}
            className="sm:mx-6 lg:mx-0 mb-8"
          />
        </div>
        <div className="mx-auto md:max-w-2/3 sm:mt-24 lg:mt-0 lg:max-w-none relative md:-right-4 flex items-center">
          <VideoPopover
            image={section.image}
            mobileImage={MOBILE_IMAGE}
            alt="Common Room product video"
            width={1024}
            vimeoId={PRODUCT_VIDEO_ID}
          />
        </div>
      </div>
      {/* </div> */}
    </SectionWrapper>
  </div>
);

export const HeroNext = ({ section }) => {
  const site = useContext(SiteContext);
  return (
    <div className="relative mx-auto">
      {/* <div
      className="absolute top-0 left-0 right-0 bottom-0 bg-blend-hue bg-green-emerald bg-cover bg-bottom bg-no-repeat"
      style={{ backgroundImage: "url('/static/img/emerald-bg.png')" }}
    ></div> */}
      {/* <Image
      src="/static/img/emerald-bg.png"
      alt={section.header}
      width={1440}
      height={1000}
      className="absolute w-full blend-multiply opacity-50" */}
      <SectionWrapper background="innerDark" padding="top" container="full">
        <div className="lg:grid lg:grid-cols-2 gap-24 pt-4 sm:pt-12 pb-6 md:pb-2">
          <div style={{ maxWidth: "500px" }}>
            <div className="text-white sm:mb-2">
              <EyebrowPill>{HERO_LABEL}</EyebrowPill>
            </div>
            <h1 className="text-8xl sm:text-9xl mr-4 sm:mr-0 md:mb-6 leading-none tracking-tight font-medium">
              {section.header}
            </h1>
          </div>
          <div className="mt-4 sm:mt-12 md:mt-16">
            <PortableText blocks={section._rawSummary} />
            <div className="flex justify-start">
              <VideoPopover
                vimeoId={PRODUCT_VIDEO_ID}
                buttonText={site.isNotMobile ? "Watch 5-min demo" : "Watch demo"}
                buttonIcon={true}
                buttonBg="bg-transparent"
                buttonClass="text-white border-white mr-3"
                isBig={true}
              />
              <Button
                text={SIGNUP_PHRASE}
                href={SIGNUP_URL}
                bgColor="bg-white"
                isBig={true}
              />
            </div>
          </div>
        </div>
        {/* <div className="flex justify-between items-end mt-4">
        <span className="py-1 px-4 rounded-full border-2 border-white text-white flex items-center">
          <FontAwesomeIcon icon={faStar} className="mr-2" />
          Theme
        </span>
        <div className="flex items-end">
          <button className="text-light-60 p-1 px-2 rounded-md hover:bg-light-30 hover:text-white transition-colors duration-300">
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button className="text-light-60 p-1 px-2 rounded-md hover:bg-light-30 hover:text-white transition-colors duration-300">
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div> */}
      </SectionWrapper>
    </div>
  )
};

export const Activities = ({ section }) => (
  <div className="overflow-hidden">
    <ActivityRow i={0} />
    <ActivityRow i={1} style={marqueeStyles.marqueeGroupReverseSpeed3} />
    <ActivityRow i={2} style={marqueeStyles.marqueeGroupSpeed2} />
    <ActivityRow i={3} style={marqueeStyles.marqueeGroupReverse} />
    <ActivityRow i={4} style={marqueeStyles.marqueeGroupSpeed3} />
  </div>
);

const ActivityRow = ({ i, style }) => (
  <div
    className={cn("flex select-none pb-2 text-white", marqueeStyles.marquee)}
  >
    <div
      className={cn(
        "flex items-center justify-around",
        marqueeStyles.marqueeGroup,
        style
      )}
    >
      {[0, 1, 2, 3].map((index) => (
        <ImageBuilder
          src={`/static/img/activities-${i}.png`}
          height={35}
          width={1498}
          alt="row of data activities"
          key={index}
        />
      ))}
    </div>
  </div>
);
